
// import React from "react";
// import { Link } from "react-router-dom";

// const OnePager = () => {
//   return (
//     <div style={{ width: "100%", height: "100vh", overflow: "hidden", position: "relative" }}>
//       {/* Sign In Link */}
//       <Link
//         to="/login" // Navigation path
//         style={{
//           position: "absolute",
//           top: "42px",
//           right: "150px",
//           color: "#fff", // White text
//           textDecoration: "none",
//           fontSize: "18px",

//           zIndex: 1000,
//         }}
//       >
//         Sign In
//       </Link>

//       {/* Iframe Section */}
//       <iframe
//         src="/OnePager/martex-tailwindcss.ibthemespro.com/demo-1.html"
//         title="One Pager"
//         style={{ width: "100%", height: "100%", border: "none" }}
//       ></iframe>
//     </div>
//   );
// };

// export default OnePager;

import React, { useState, useEffect , useRef} from "react"
import {
  Container,
  Row,
  Col,
  Dropdown,
  Button,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardTitle,
  CardText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { motion } from "framer-motion";
import { Navigate, useNavigate } from "react-router-dom"
import logoLight from "../../assets/images/Logo.png"
import HeroImage from "../../assets/images/hero-1.jpg"
import HeroImage1 from "../../assets/images/hero-1-img.png"
import HeroImage2 from "../../assets/images/hero-2-img.png"
import backgroundImage from "../../assets/images/bg-01.jpg"
import backgroundImage1 from "../../assets/images/bg-02.jpg"

const OnePager = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen)
  const [isOpen, setIsOpen] = useState(false)

  const [navbarBg, setNavbarBg] = useState("transparent")
  const [textColor, setTextColor] = useState("white")
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(null)

  const industriesRef = useRef(null);
  const aboutPostReviewsRef = useRef(null);
  const howItWorksRef = useRef(null);
  const whyChooseRef = useRef(null);
  const testimonialsRef = useRef(null);
  const navigate = useNavigate()
  const drawerRef = useRef(null);

  // Handle Navbar Background & Text Color on Scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarBg("white");
        setTextColor("black");
      } else {
        setNavbarBg("transparent");
        setTextColor("white");
      }
    };
  
    const closeDrawerOnOutsideClick = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setIsDrawerOpen(false);
      }
    };
  
    // Add scroll listener
    window.addEventListener("scroll", handleScroll);
  
    // Add drawer outside click listener
    if (isDrawerOpen) {
      document.addEventListener("mousedown", closeDrawerOnOutsideClick);
    } else {
      document.removeEventListener("mousedown", closeDrawerOnOutsideClick);
    }
  
    // Cleanup both listeners
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", closeDrawerOnOutsideClick);
    };
  }, [isDrawerOpen]);
  
  const toggleNavbar = () => {
    setIsOpen(!isOpen)
  }
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }
  const handleDropdownToggle = menu => {
    setOpenDropdown(openDropdown === menu ? null : menu)
  }
  const scrollToIndustries = () => {
    industriesRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToAboutPostReview = () => {
    aboutPostReviewsRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToHowItWorks = () => {
    howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToWhyChoose = () => {
    whyChooseRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToTestimonials = () => {
    testimonialsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const closeDrawerOnOutsideClick = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setIsDrawerOpen(false);
    }
  };
  
  

  return (
    <div>
      {/* Hero Section */}
      <div
        className="hero-section"
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          color: "#fff",
          padding: "100px 0",
          minHeight: "100vh",
        }}
      >
        {/* Main Navbar */}
        <Navbar
  expand="md"
  style={{
    backgroundColor: navbarBg,
    position: "fixed",
    top: "0px",
    width: "100%",
    zIndex: 1000,
    transition: "background-color 0.3s ease-in-out",
    padding: "10px 20px",
    boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
  }}
>
  <Container className="d-flex justify-content-between align-items-center">
    <NavbarBrand href="/">
      <img
        src={logoLight}
        alt="PostReview Logo"
        style={{ maxHeight: "60px" }}
      />
    </NavbarBrand>

    {/* Navbar Toggler for Mobile */}
    <NavbarToggler onClick={toggleDrawer} style={{ color: textColor }}>
      ☰
    </NavbarToggler>

    <Collapse isOpen={isOpen} navbar>
      <Nav className="ms-auto d-flex align-items-center" navbar>
        {/* About Dropdown */}
        <Dropdown
          nav
          isOpen={openDropdown === "about"}
          toggle={() => handleDropdownToggle("about")}
        >
          <DropdownToggle
            nav
            caret
            style={{
              fontSize: "18px", // Increased font size
              fontWeight: "500", // Bold font
              color: textColor,
              padding: "10px 10px", // Increased padding for larger dropdown
            }}
          >
            About
          </DropdownToggle>
          <DropdownMenu
            style={{
              fontSize: "16px", // Increased font size for dropdown items
              padding: "10px 10px", // Increased padding
            }}
          >
           <DropdownItem onClick={() => scrollToAboutPostReview("aboutPostReviewsRef")}>About PostReview</DropdownItem>

            <DropdownItem onClick={() => scrollToHowItWorks("howItWorksRef")}>How It Works</DropdownItem>
            <DropdownItem onClick={() => scrollToWhyChoose("whyChooseRef")}>Why Choose PostReview?</DropdownItem>
            <DropdownItem onClick={() => scrollToTestimonials("testimonialsRef")}>Testimonials</DropdownItem>
          </DropdownMenu>
        </Dropdown>

        {/* Other Navbar Links */}
        {["Features"].map((item, index) => (
          <NavItem className="me-3" key={index}>
            <NavLink
              onClick={scrollToIndustries} // Add this to trigger scroll
              style={{
                fontSize: "18px",
                fontWeight: "500",
                color: textColor,
                cursor: "pointer", // Make it look clickable
              }}
            >
              {item}
            </NavLink>
          </NavItem>
        ))}

        {/* Sign-Up Button */}
        <NavItem>
        <Button onClick={() => navigate("/login")} color="danger">
                    Sign Up
                  </Button>
        </NavItem>
      </Nav>
    </Collapse>
  </Container>
</Navbar>


        {/* Mobile Drawer Menu */}
        <div
          className="mobile-drawer"
          ref={drawerRef}
          style={{
            position: "fixed",
            top: 0,
            right: isDrawerOpen ? "0" : "-250px",
            width: "250px",
            height: "100vh",
            background: "white",
            boxShadow: "-2px 0px 5px rgba(0,0,0,0.3)",
            transition: "right 0.3s ease-in-out",
            padding: "20px",
            zIndex: 2000,
          }}
        >
          <button
  onClick={toggleDrawer}
  style={{
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
  }}
>
  ×
</button>
          <div style={{ marginTop: "30px" }}>
          <Nav vertical >
            {/* About Dropdown in Mobile */}
            <Dropdown
              nav
              isOpen={openDropdown === "aboutMobile"}
              toggle={() => handleDropdownToggle("aboutMobile")}
            >
              <DropdownToggle nav caret>
                About
              </DropdownToggle>
              <DropdownMenu>
              <DropdownItem onClick={() => scrollToAboutPostReview("aboutPostReviewsRef")}>About PostReview</DropdownItem>

<DropdownItem onClick={() => scrollToHowItWorks("howItWorksRef")}>How It Works</DropdownItem>
<DropdownItem onClick={() => scrollToWhyChoose("whyChooseRef")}>Why Choose PostReview?</DropdownItem>
<DropdownItem onClick={() => scrollToTestimonials("testimonialsRef")}>Testimonials</DropdownItem>
              </DropdownMenu>
            </Dropdown>

            {["Features"].map((item, index) => (
  <NavItem key={index}>
    <NavLink
      href={`#${item.toLowerCase()}`}
      onClick={() => {
        toggleDrawer(); // Toggle drawer
        scrollToIndustries(); // Scroll to Industries section
      }}
    >
      {item}
    </NavLink>
  </NavItem>
))}


            <NavItem>
            <Button color="danger" href="/login" onClick={toggleDrawer}>
                Sign Up
              </Button>
            </NavItem>
          </Nav>
          </div>
        </div>
        
        {/* Hero Content */}
        <Container>
  <Row className="align-items-center" style={{ minHeight: "80vh" }}>
    {/* Left Column: Text Content */}
    <Col md="6" className="text-center text-md-start">
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <h1
  style={{
    fontSize: "3.5rem",
    fontWeight: "bold",
    lineHeight: "1.2",
    ...(window.innerWidth < 768 && { fontSize: "2.5rem" }), // Smaller font for mobile
  }}
>
  Effortlessly Generate and Post Google Reviews with AI
</h1>
        
      </motion.div>
      <motion.p
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, delay: 0.2 }}
        style={{
          marginTop: "20px",
          fontSize: "18px",
          lineHeight: "1.5",
        }}
      >
        Transform your reviews by – Scan, Generate, and Post in seconds.
      </motion.p>
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, delay: 0.4 }}
        style={{ marginTop: "20px" }}
      >
        <Button
          color="danger"
          size="lg"
          style={{
            padding: "10px 20px",
            marginRight: "15px",
          }}
        >
          Start Free Trial
        </Button>
        <Button
          outline
          color="light"
          size="lg"
          style={{
            padding: "10px 20px",
          }}
        >
          Learn More
        </Button>
      </motion.div>
    </Col>

    {/* Right Column: Hero Image */}
    <Col md="6" className="text-center mt-4 mt-md-0">
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <img
          src={HeroImage1}
          alt="Hero Illustration"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </motion.div>
    </Col>
  </Row>
</Container>
      </div>


      {/* Industries Section */}
      <div
  id="industries"
  ref={industriesRef} // Attach the ref here
  className="industries-section"
  style={{ backgroundColor: "#ffffff", padding: "60px 0" }}
>
  <Container>
    <h1
      className="text-center"
      style={{
        fontSize: "2.5rem",
        fontWeight: "bold",
        marginBottom: "20px",
      }}
    >
      Industries We Can Help With
    </h1>
    <p
      className="text-center"
      style={{
        fontSize: "1.2rem",
        color: "#555",
        marginBottom: "40px",
      }}
    >
      PostReview is designed to revolutionize the way businesses across
      various industries gather and manage reviews.
    </p>
    <Row className="text-center">
      {[
        {
          icon: "mdi-office-building",
          title: "Hospitality",
          description:
            "Enhance guest experiences and improve your online reputation. Increase online reviews to attract more customers and boost SEO ranking for local searches.",
        },
        {
          icon: "mdi-shopping",
          title: "Retail",
          description:
            "Increase customer feedback and improve product visibility, leading to better customer engagement. Enhance customer feedback and improve product visibility, leading to better customer engagement.",
        },
        {
          icon: "mdi-heart",
          title: "Healthcare",
          description:
            "Gain insights into patient satisfaction, improve patient trust, and strengthen your online reputation.",
        },
        {
          icon: "mdi-airplane",
          title: "Tourism",
          description:
            "Boost customer reviews to increase visibility in the competitive travel market and improve customer experience.",
        },
        {
          icon: "mdi-car",
          title: "Automotive",
          description:
            "Enhance customer satisfaction by collecting detailed feedback and improving service quality.",
        },
        {
          icon: "mdi-desktop-mac",
          title: "Technology",
          description:
            "Gather valuable feedback to refine your products and services, and improve customer satisfaction.",
        },
        {
          icon: "mdi-factory",
          title: "Manufacturing",
          description:
            "Collect feedback on product quality and customer satisfaction to drive continuous improvement.",
        },
        {
          icon: "mdi-home-city",
          title: "Real Estate",
          description:
            "Improve client feedback to enhance your reputation and attract more buyers and renters.",
        },
        {
          icon: "mdi-movie",
          title: "Entertainment",
          description:
            "Boost reviews for events, venues, and services to attract more customers and improve visibility.",
        },
      ].map((industry, index) => (
        <Col lg="4" md="6" className="mb-4" key={index}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            style={{ textAlign: "center", position: "relative" }}
          >
            {/* SVG Background */}
            <svg
              style={{
                position: "absolute",
                width: "120px",
                height: "120px",
                top: "0",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: "1",
              }}
              viewBox="0 0 200 200"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                transform="translate(100 100)"
                fill="#ffe8ef"
              />
            </svg>

            {/* Icon */}
            <i
              className={`mdi ${industry.icon}`}
              style={{
                fontSize: "3.5rem",
                color: "#e83e8c",
                position: "relative",
                zIndex: "2",
              }}
            ></i>

            {/* Title and Description */}
            <h5 style={{ fontWeight: "bold", marginTop: "20px" }}>
              {industry.title}
            </h5>
            <p style={{ color: "#555", fontSize: "0.9rem" }}>
              {industry.description}
            </p>
          </motion.div>
        </Col>
      ))}
    </Row>
  </Container>
  <hr></hr>
</div>

{/* About PostReview Section */}
<div
  id="about-postreview"
  ref={aboutPostReviewsRef}
  className="about-section"
  style={{ backgroundColor: "#ffffff", padding: "10px 0" }}
>
  <Container>
    <Row className="align-items-center">
      {/* Left Side: Image */}
      <Col
        lg="6"
        md="12"
        className="text-center mb-4 mb-lg-0"
        style={{ position: "relative", overflow: "hidden" }}
      >
        <motion.div
          initial={{ opacity: 0, x: window.innerWidth < 768 ? 0 : -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <img
            src={HeroImage2}
            alt="About PostReview"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </motion.div>
      </Col>

      {/* Right Side: Content */}
      <Col lg="6" md="12" style={{ overflow: "hidden" }}>
        <motion.div
          initial={{ opacity: 0, x: window.innerWidth < 768 ? 0 : 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <div style={{ marginLeft: "20px" }}>
            <h1
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              About PostReview
            </h1>
            <p
              style={{
                fontSize: "1.2rem",
                marginBottom: "30px",
                color: "#555",
                lineHeight: "1.6",
              }}
            >
              PostReview is an AI-powered platform that simplifies the review-writing process. Quickly generate high-quality reviews and enhance your online presence.
            </p>
            <ul
              style={{
                listStyleType: "none",
                padding: 0,
              }}
            >
              {[
                {
                  icon: "mdi mdi-lightbulb-on-outline",
                  title: "AI-Generated Reviews",
                  description: "Personalized, articulate reviews crafted by AI.",
                },
                {
                  icon: "mdi mdi-qrcode",
                  title: "Easy QR Code Access",
                  description: "Instant access via QR code.",
                },
                {
                  icon: "mdi mdi-google",
                  title: "Seamless Google Review Posting",
                  description: "Direct redirection to Google Reviews for submission.",
                },
              ].map((item, index) => (
                <motion.li
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: index * 0.2 }}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginBottom: index === 2 ? "0" : "30px",
                  }}
                >
                  <i
                    className={item.icon}
                    style={{
                      fontSize: "2rem",
                      color: "#e83e8c",
                      marginRight: "15px",
                    }}
                  ></i>
                  <div>
                    <h5
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        marginBottom: "5px",
                      }}
                    >
                      {item.title}
                    </h5>
                    <p style={{ margin: 0, color: "#555" }}>
                      {item.description}
                    </p>
                  </div>
                </motion.li>
              ))}
            </ul>
          </div>
        </motion.div>
      </Col>
    </Row>
  </Container>
</div>

 


{/* How It Works Section */}
<div
      id="how-it-works"
      ref={howItWorksRef}
      className="how-it-works-section"
      style={{
        backgroundImage: `url(${backgroundImage1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "80px 0",
        backgroundColor: "#f8f9fa", // fallback color
      }}
    >
      <Container>
        <h2
          className="text-center"
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            marginBottom: "50px",
            color: "#333",
          }}
        >
          How It Works
        </h2>
        <Row className="position-relative">
          {/* Step 1 */}
          <Col lg="4" md="4" className="text-center mb-5">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 1, // Longer duration for smoother animation
                ease: [0.25, 0.8, 0.25, 1], // Custom easing function for smoothness
              }}
              style={{
                position: "relative",
                padding: "40px",
                backgroundColor: "#fff",
                borderRadius: "12px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                maxWidth: "300px",
                margin: "0 auto",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-30px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "70px",
                  height: "70px",
                  backgroundColor: "#e83e8c",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <i
                  className="mdi mdi-qrcode-scan"
                  style={{
                    fontSize: "2rem",
                    color: "#fff",
                  }}
                ></i>
              </div>
              <h5 style={{ fontWeight: "bold", marginTop: "40px", fontSize: "1.5rem" }}>
                Step 1
              </h5>
              <h6 style={{ fontWeight: "bold", color: "#e83e8c", fontSize: "1.2rem" }}>
                Scan the QR Code
              </h6>
              <p style={{ color: "#555", fontSize: "0.9rem" }}>
                Access the platform instantly.
              </p>
            </motion.div>
          </Col>

          {/* Step 2 */}
          <Col lg="4" md="4" className="text-center mb-5">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 1,
                ease: [0.25, 0.8, 0.25, 1],
              }}
              style={{
                position: "relative",
                padding: "40px",
                backgroundColor: "#fff",
                borderRadius: "12px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                maxWidth: "300px",
                margin: "0 auto",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-30px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "70px",
                  height: "70px",
                  backgroundColor: "#e83e8c",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <i
                  className="mdi mdi-checkbox-marked-outline"
                  style={{
                    fontSize: "2rem",
                    color: "#fff",
                  }}
                ></i>
              </div>
              <h5 style={{ fontWeight: "bold", marginTop: "40px", fontSize: "1.5rem" }}>
                Step 2
              </h5>
              <h6 style={{ fontWeight: "bold", color: "#e83e8c", fontSize: "1.2rem" }}>
                Select Your Options
              </h6>
              <p style={{ color: "#555", fontSize: "0.9rem" }}>
                Choose your rating and review highlights.
              </p>
            </motion.div>
          </Col>

          {/* Step 3 */}
          <Col lg="4" md="4" className="text-center mb-5">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 1,
                ease: [0.25, 0.8, 0.25, 1],
              }}
              style={{
                position: "relative",
                padding: "40px",
                backgroundColor: "#fff",
                borderRadius: "12px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                maxWidth: "300px",
                margin: "0 auto",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-30px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "70px",
                  height: "70px",
                  backgroundColor: "#e83e8c",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <i
                  className="mdi mdi-send-circle-outline"
                  style={{
                    fontSize: "2rem",
                    color: "#fff",
                  }}
                ></i>
              </div>
              <h5 style={{ fontWeight: "bold", marginTop: "40px", fontSize: "1.5rem" }}>
                Step 3
              </h5>
              <h6 style={{ fontWeight: "bold", color: "#e83e8c", fontSize: "1.2rem" }}>
                Generate & Post
              </h6>
              <p style={{ color: "#555", fontSize: "0.9rem" }}>
                AI crafts your review; you post it with one click.
              </p>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>

    
{/* Why Choose PostReview Section */}
<div
  id="why-choose"
  ref={whyChooseRef}
  className="why-choose-section"
  style={{
    padding: "100px 0",
    backgroundImage:`url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  }}
>
  <Container>
    <h1
      className="text-center"
      style={{
        fontSize: "2.5rem",
        fontWeight: "bold",
        color: "#333",
        marginBottom: "60px",
        color: "#ffffff",
      }}
    >
      Why Choose PostReview?
    </h1>
    <Row className="align-items-center">
      <Col lg="4" md="6" className="text-center mb-4">
        <div
          style={{
            padding: "30px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "80px",
              height: "80px",
              backgroundColor: "#e83e8c",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto 20px auto",
            }}
          >
            <i
              className="mdi mdi-briefcase-check-outline"
              style={{
                fontSize: "2rem",
                color: "#fff",
              }}
            ></i>
          </div>
          <h4 style={{ fontWeight: "bold", marginBottom: "10px" }}>
            For Businesses
          </h4>
          <p style={{ color: "#555", fontSize: "1rem" }}>
            Boost your online reputation effortlessly.
          </p>
        </div>
      </Col>
      <Col lg="4" md="6" className="text-center mb-4">
        <div
          style={{
            padding: "30px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "80px",
              height: "80px",
              backgroundColor: "#20c997",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto 20px auto",
            }}
          >
            <i
              className="mdi mdi-chart-bar"
              style={{
                fontSize: "2rem",
                color: "#fff",
              }}
            ></i>
          </div>
          <h4 style={{ fontWeight: "bold", marginBottom: "10px" }}>
            More Reviews, More Customers
          </h4>
          <p style={{ color: "#555", fontSize: "1rem" }}>
            Businesses with over 50 reviews see a 70% increase in customer
            engagement.
          </p>
        </div>
      </Col>
      <Col lg="4" md="12" className="text-center mb-4">
        <div
          style={{
            padding: "30px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "80px",
              height: "80px",
              backgroundColor: "#ffc107",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto 20px auto",
            }}
          >
            <i
              className="mdi mdi-google-maps"
              style={{
                fontSize: "2rem",
                color: "#fff",
              }}
            ></i>
          </div>
          <h4 style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Rank 1st in Google Reviews
          </h4>
          <p style={{ color: "#555", fontSize: "1rem" }}>
            Increase your chances of ranking 1st in your location with
            consistent, high-quality reviews.
          </p>
        </div>
      </Col>
    </Row>
  </Container>
</div>

{/* Testimonials Section */}
<div
  id="testimonials"
  ref={testimonialsRef}
  className="testimonials-section"
  style={{
    backgroundColor: "#f1f1f1",
    padding: "80px 0",
  }}
>
  <Container>
    <h2
      className="text-center"
      style={{
        fontSize: "2.5rem",
        fontWeight: "bold",
        marginBottom: "50px",
        color: "#333",
      }}
    >
      Here’s what our amazing clients are saying
    </h2>
    <Row>
      {/* Testimonial 1 */}
      <Col lg="6" md="6" className="text-center mb-4 d-flex">
        <div
          style={{
            padding: "40px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <p style={{ fontSize: "1.1rem", color: "#555", marginBottom: "20px" }}>
            "PostReview has been a game-changer for our business. The AI-generated reviews have significantly improved our online reputation and customer engagement. The platform is easy to use and integrates seamlessly with our existing systems."
          </p>
          <h5 style={{ fontWeight: "bold", fontSize: "1.2rem", color: "#e83e8c" }}>
            — John Doe, Marketing Manager, XYZ Corporation
          </h5>
        </div>
      </Col>

      {/* Testimonial 2 */}
      <Col lg="6" md="6" className="text-center mb-4 d-flex">
        <div
          style={{
            padding: "40px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <p style={{ fontSize: "1.1rem", color: "#555", marginBottom: "20px" }}>
            "The simplified review process has made it incredibly easy for our customers to leave feedback. PostReview's automated responses have also helped us maintain a positive image online."
          </p>
          <h5 style={{ fontWeight: "bold", fontSize: "1.2rem", color: "#e83e8c" }}>
            — Jane Smith, Store Manager, ABC Retail
          </h5>
        </div>
      </Col>
    </Row>
  </Container>
</div>

{/* Contact Us Section */}   

<div
  id="contact-us"
  style={{
    padding: "50px 0",
    backgroundColor: "#f8f9fa",
  }}
>
  <Container>
    <h2
      className="text-center"
      style={{
        fontSize: "2rem",
        fontWeight: "bold",
        marginBottom: "20px",
      }}
    >
      Contact Us
    </h2>
    <p
      className="text-center"
      style={{
        fontSize: "1rem",
        marginBottom: "30px",
      }}
    >
      Ready to transform your business with PostReview? Contact us today to
      learn more about our platform and how it can help you grow.
    </p>
    <div style={{ textAlign: "center", fontSize: "1rem" }}>
      <p>Email: contact@postaireview.com</p>
      <p>Phone: +91 9662552666</p>
      <p>Address: Pune, India</p>
    </div>
  </Container>
</div>

{/* Footer Section */}
<footer
  style={{
    backgroundColor: "#fff",
    padding: "50px 0",
    borderTop: "1px solid #e9ecef",
  }}
>
  <Container>
    <Row>
      {/* Logo Section */}
      <Col md={3} className="mb-4">
        <img
           src={logoLight}
          alt="PostReview Logo"
          style={{ maxWidth: "150px" }}
        />
      </Col>

      {/* Quick Links */}
      <Col md={3} className="mb-4">
        <h5
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            color: "#343a40",
            marginBottom: "20px",
          }}
        >
          Quick Links
        </h5>
        <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
          <li style={{ marginBottom: "15px" }}>
            <a
              href="#home"
              style={{ textDecoration: "none", color: "#6c757d", fontSize: "1rem" }}
            >
              Home
            </a>
          </li>
          <li style={{ marginBottom: "15px" }}>
            <a
              href="#about"
              style={{ textDecoration: "none", color: "#6c757d", fontSize: "1rem" }}
            >
              About
            </a>
          </li>
          <li style={{ marginBottom: "15px" }}>
            <a
              href="#pricing"
              style={{ textDecoration: "none", color: "#6c757d", fontSize: "1rem" }}
            >
              Pricing
            </a>
          </li>
          <li>
            <a
              href="#contact"
              style={{ textDecoration: "none", color: "#6c757d", fontSize: "1rem" }}
            >
              Contact
            </a>
          </li>
        </ul>
      </Col>

      {/* Legal */}
      <Col md={3} className="mb-4">
        <h5
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            color: "#343a40",
            marginBottom: "20px",
          }}
        >
          Legal
        </h5>
        <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
          <li style={{ marginBottom: "15px" }}>
            <a
              href="#privacy-policy"
              style={{ textDecoration: "none", color: "#6c757d", fontSize: "1rem" }}
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="#terms"
              style={{ textDecoration: "none", color: "#6c757d", fontSize: "1rem" }}
            >
              Terms of Service
            </a>
          </li>
        </ul>
      </Col>
    </Row>
    <div
      style={{
        borderTop: "1px solid #e9ecef",
        marginTop: "30px",
        paddingTop: "20px",
        textAlign: "center",
        fontSize: "1rem",
        color: "#6c757d",
      }}
    >
      &copy; 2024 PostReview. All rights reserved.
    </div>
  </Container>
</footer>



    </div>
  );
};

export default OnePager;

