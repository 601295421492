import React, { useState, useEffect,useRef } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Spinner,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
// import Logo from "../../assets/images/Jackmex.jpg"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const AIReviewCreation = () => {
  const [activeStars, setActiveStars] = useState(0)
  const [buttonLabel, setButtonLabel] = useState("Generate AI Review")
  const [clickedDishesKeywords, setClickedDishesKeywords] = useState([])
  const [clickedReviewKeywords, setClickedReviewKeywords] = useState([])
  const [showAllDishesKeywords, setShowAllDishesKeywords] = useState(false)
  const [showAllReviewKeywords, setShowAllReviewKeywords] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [copySuccess, setCopySuccess] = useState(false)
  console.log("clickedDishesKeywords", clickedDishesKeywords)
  console.log("showAllDishesKeywords", showAllDishesKeywords)
  const [queMerchData, setQueMerchData] = useState({})
  const [allReviews, setAllReviews] = useState("")
  const [generatedText, setGeneratedText] = useState("")

  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [reviewloading, setReviewLoading] = useState(false)
  const [error, setError] = useState(null)
  const [logoUrl, setLogoUrl] = useState("")
  const [isReviewGenerated, setIsReviewGenerated] = useState(false)
  const [customReviewKeyword, setCustomReviewKeyword] = useState("")
  const [customDishKeyword, setCustomDishKeyword] = useState("")
  const [customDishKeywords, setCustomDishKeywords] = useState([])
  const [customReviewKeywords, setCustomReviewKeywords] = useState([])
  const params = useParams()
  const [reviewLength, setReviewLength] = useState("short")
  const starRatingRef = useRef(null);
  const keyword1Ref = useRef(null);
  const keyword2Ref = useRef(null);
  const copyButtonRef = useRef(null);


  // const reviewLengthWords =
  //   reviewLength === "short"
  //     ? Math.floor(Math.random() * (20 - 10 + 1)) + 10 // Random number between 10 and 20
  //     : Math.floor(Math.random() * (50 - 30 + 1)) + 30 // Random number between 30 and 100

  // const reviewLengthWords =
  //   reviewLength === "short"
  //     ? Math.floor(1 + Math.random() * (10 - 1)) // Random number between 1 and 20
  //     : Math.floor(1 + Math.random() * (20 - 1)) // Random number between 1 and 50
  /**************************************** */
  const reviewLengthWords =
    reviewLength === "short" ? " in 10 to 30 " : " in more than 50 "
  const { businessname } = params
  console.log("params", params)

  // Moved the dependency array outside the function
  useEffect(() => {

    const fetchQueMerchData = async () => {
      try {
        setLoading(true)
        const response = await axios.get(
          `${API_BASE_URL}/api/getData/${businessname}`,
        )
        if (response && response.data) {
          setQueMerchData(response.data)

          // Handle logo setting directly after data fetch
          const logo = response.data?.MerchantsTable?.logo

          if (typeof logo === "string") {
            // Directly set the logo if it's a URL string
            setLogoUrl(logo)
          } else if (logo?.data) {
            // Convert buffer to Blob URL if necessary
            const convertBufferToBlobUrl = bufferData => {
              const blob = new Blob([new Uint8Array(bufferData)], {
                type: "image/jpeg", // Adjust MIME type as needed
              })
              return URL.createObjectURL(blob)
            }

            const generatedLogoUrl = convertBufferToBlobUrl(logo.data)
            setLogoUrl(generatedLogoUrl)
          } else {
            setLogoUrl(null)
          }
        } else {
          setError("No reviews found")
          setLogoUrl(null) // Clear logo in case of missing data
        }
      } catch (error) {
        console.error("Error fetching data:", error)
        setError(`${navigate("/pages-404")}`)
        setLogoUrl(null)
      } finally {
        setLoading(false)
      }
    }

    fetchQueMerchData()
  }, [businessname])
  useEffect(() => {

    console.log("Star rating ref after render:", starRatingRef.current);
    console.log("Rendered Copy Button:", copyButtonRef.current);

    if (queMerchData?.MerchantsTable?.isActiveSubscription === false) {
      navigate("/subscription-status")
    }
  }, [queMerchData])

  const addCustomReviewKeyword = () => {
    if (
      customReviewKeyword &&
      !clickedReviewKeywords.includes(customReviewKeyword)
    ) {
      // Add the custom keyword to the clickedReviewKeywords list
      setClickedReviewKeywords([...clickedReviewKeywords, customReviewKeyword])

      // Reset the custom keyword input field
      setCustomReviewKeyword("")
    }
  }

  const addCustomDishKeyword = () => {
    if (
      customDishKeyword &&
      !clickedDishesKeywords.includes(customDishKeyword)
    ) {
      // Add the custom keyword to the clickedDishesKeywords list
      setClickedDishesKeywords([...clickedDishesKeywords, customDishKeyword])

      // Reset the custom keyword input field
      setCustomDishKeyword("")
    }
  }
  const lowRatingEmotions = [
    "Frustration",
    "Disappointment",
    "Dissatisfaction",
    "Annoyance",
    "Regret",
    "Irritation",
    "Unhappiness",
    "Anger",
    "Unfulfilled expectations",
    "Letdown",
  ]

  const neutralRatingEmotions = [
    "Confusion",
    "Indifference",
    "Mild disappointment",
    "Mild confusion",
    "Uncertainty",
    "Unimpressed",
    "Lukewarm",
    "Unconcerned",
    "Mixed feelings",
    "Reluctance",
  ]

  const highRatingEmotions = [
    "Joy",
    "Excitement",
    "Gratitude",
    "Satisfaction",
    "Delight",
    "Happiness",
    "Amazement",
    "Pride",
    "Contentment",
    "Admiration",
  ]

  //sentences
  const lowRatingSentence = [
    "This was a terrible experience because…",
    "I can’t believe how bad this turned out in…",
    "I wouldn’t recommend this at all due to…",
    "It was a complete disaster, especially in…",
    "This failed miserably in every way, especially…",
    "I had an awful time dealing with…",
    "There’s absolutely no value in this because…",
    "This product/service is unacceptable due to…",
    "I’ve never been so disappointed by…",
    "This was nothing like what was promised, especially…",
    "I feel like I wasted my money because…",
    "I’m shocked at how poor the quality was in…",
    "This was by far the worst decision I’ve made, considering…",
    "Everything about this experience was frustrating, from…",
    "This was incredibly underwhelming due to…",
    "The service was horrible, particularly because…",
    "This was the most disappointing purchase because…",
    "It’s unacceptable that I had to deal with…",
    "The entire experience was ruined by…",
    "I wish I could give zero stars because…",
    "This was an absolute letdown in terms of…",
    "I wouldn’t use this again because…",
    "Nothing about this was worth the price, especially…",
    "This turned out to be completely useless because…",
    "I regret ever trying this due to…",
    "This was slightly better than terrible because…",
    "It wasn’t horrible, but it was far from good because…",
    "There’s some effort here, but it’s not enough due to…",
    "The experience left much to be desired, especially in…",
    "This had potential, but it missed the mark because…",
    "I can’t call this good because of…",
    "It was tolerable, but I noticed significant issues with…",
    "While it worked, it was very disappointing in…",
    "It felt mediocre overall, especially in terms of…",
    "I struggled to enjoy this due to…",
    "This was underwhelming, but I can see potential if they fix…",
    "It’s passable, but it definitely needs improvement in…",
    "It’s hard to overlook the flaws in…",
    "The experience was okay at best, but it fell short because…",
    "It wasn’t the worst, but the issues with…",
    "This left me feeling frustrated, particularly because…",
    "There are too many glaring problems, such as…",
    "It worked to some extent, but I can’t recommend it due to…",
    "This just wasn’t worth the hassle because of…",
    "While I didn’t completely hate it, the experience was ruined by…",
    "It’s not great and left me feeling underwhelmed because…",
    "This was barely acceptable, mainly due to…",
    "I can’t call this a good experience because of…",
    "It’s tolerable, but the issues with…",
    "This could have been much better if they addressed…",
  ]

  const neutralRatingSentence = [
    "The experience was okay, but...",
    "I had mixed feelings about...",
    "There were some good aspects, but also some drawbacks...",
    "It was decent overall, though...",
    "I found the service to be average...",
    "The visit was fine, but nothing stood out...",
    "There’s room for improvement, especially in...",
    "Some parts were enjoyable, but others fell short...",
    "While it wasn’t bad, it didn’t wow me...",
    "I felt like it was just okay, mainly because...",
    "It was neither great nor terrible...",
    "I appreciated some elements, but...",
    "The experience could have been better with...",
    "It was fine, though I noticed...",

    "The visit had its ups and downs...",
    "I had an average experience at...",
    "The service was alright, but...",
    "I wouldn’t call it bad, but it wasn’t amazing either...",
    "There were some highlights, but also a few issues...",
    "It left me feeling neutral overall...",
    "Not the best, but certainly not the worst...",
    "I think it was okay, though...",
    "Some things were nice, but others could improve...",
    "While I didn’t dislike it, I also didn’t love...",
    "It was a middle-of-the-road experience...",
    "The experience was so-so because...",
    "I feel like it could have been better if...",
    "The overall impression was average...",
    "It didn’t fully meet my expectations...",
    "While some aspects were enjoyable, others...",
    "The experience was a mix of positives and negatives...",
    "There were good moments, but they were balanced out by...",
    "It wasn’t quite what I was hoping for...",
    "The overall quality was okay, but...",
    "It’s hard to feel strongly about it because...",
    "There were a few issues that stood out...",
    "I’d say it was fine, but it lacked...",
    "It was average, though it could have been...",
    "The experience left me feeling indifferent...",
    "I had a satisfactory time, though...",
    "There’s potential here, but...",
    "The quality was inconsistent because...",
    "It was okay, but there’s a need to...",
    "Some parts were good, but overall...",
    "It could have been better with a little more effort on...",
    "While it wasn’t bad, it wasn’t great either...",
    "The visit was fine, though I was hoping for...",
    "I’d describe the experience as fair...",
    "I feel like it was just okay, mainly due to...",
    "While the service was alright, the rest...",
    "It didn’t quite live up to my expectations...",
    "There were both good and not-so-good moments...",
    "The experience was okay, but I’ve had better...",
    "I feel like it was middle-of-the-road...",
    "While there were some positives, the negatives...",
    "It was decent, though not memorable...",
    "The visit was okay, but lacked something special...",
    "I wasn’t overly impressed, but it wasn’t terrible...",
    "It could have been much better with...",
    "I appreciated certain aspects, but...",
    "There’s room for improvement in...",
    "While some parts were good, others felt lacking...",
    "I think it was okay overall, but...",
    "It was fine, but not quite what I expected...",
    "I walked away feeling neutral because...",
    "Some improvements would make this much better...",
    "I didn’t love it, but I didn’t hate it either...",
    "It was okay, though it could have been more...",
    "While it wasn’t bad, there were definitely areas to...",
    "I would describe the experience as average at best...",
    "While there were good points, I was let down by...",
    "It was a satisfactory experience, though...",
    "The visit didn’t stand out in any major way...",
    "The service was okay, but I noticed...",
    "It wasn’t the worst experience, but...",
    "The visit was neither here nor there because...",
    "I’d say it was acceptable, but...",
    "While it wasn’t disappointing, it wasn’t exciting either...",
    "There’s potential for improvement, particularly in...",
    "Some parts were fine, but others could be better...",
    "It’s a mixed bag in terms of...",
    "I thought it was okay, but...",

    "There’s room for growth, especially in...",
    "It was an average experience due to...",
    "I had a decent time, but...",
    "Some tweaks could make this place much better...",
    "While I didn’t dislike it, I wasn’t thrilled by...",
    "It was okay overall, though I noticed...",
    "The experience was just fine, but...",
    "I think it was alright, but it lacked...",
    "It’s clear that improvements could be made in...",
    "The overall vibe was fine, though...",
    "While it was okay, it didn’t leave a lasting impression...",
    "It was a fair experience with room for...",
    "The visit didn’t quite meet my standards...",
    "I would call it average, but there were some positives in...",
    "It’s not bad, but it’s not great either...",
    "It left me feeling neutral, especially since...",
    "While there’s potential, the experience felt...",
    "It was okay, but not quite what I was expecting...",
  ]

  const highRatingSentence = [
    "From the moment I arrived...",
    "I was immediately impressed by...",
    "The atmosphere here is...",
    "What stood out the most was...",
    "I couldn’t believe how...",
    "This place has a way of making you feel...",
    "Every detail was thoughtfully...",
    "Stepping inside, I felt...",
    "The service I received was...",
    "I can’t stop thinking about...",
    "The staff went above and beyond to...",
    "It’s rare to find a place where...",
    "I’m so glad I chose to...",
    "Every aspect of this experience was...",
    "You can tell they really care about...",
    "The level of care here is...",
    "This was hands down the best...",
    "I’ve never experienced anything quite like...",
    "It’s hard to put into words how...",
    "I was blown away by...",
    "Every time I visit, I’m reminded of...",
    "It’s clear they take pride in...",
    "The moment I stepped through the door...",
    "I can’t recommend this place enough for...",
    "Everything about this place is...",
    "They truly understand how to...",
    "I’ve been to many places, but this one...",
    "This is my go-to spot for...",
    "From start to finish, the experience was...",
    "They’ve mastered the art of...",
    "The energy in this place is...",
    "I felt so welcomed the moment...",
    "It’s easy to see why so many people love...",
    "Each visit is better than the last...",
    "I’m always impressed by how...",
    "You won’t regret choosing...",
    "This place has something special...",
    "The quality here speaks for itself...",
    "I left feeling so...",
    "If you’re looking for a place where...",
    "What an amazing experience I had at...",
    "It’s the little things that make...",
    "I can’t imagine going anywhere else for...",
    "I felt like a valued customer from...",
    "Their attention to detail is...",
    "The entire experience was nothing short of...",
    "They’ve set the bar so high for...",
    "Everything was handled with such...",
    "I walked away feeling so...",
    "It’s clear that this place values...",
    "The team here is so...",
    "I’m thrilled to have discovered...",
    "They’ve got everything covered when it comes to...",
    "Every interaction here is...",
    "This was such a memorable...",
    "They really know how to make you feel...",
    "You’ll be glad you chose...",
    "I can’t believe how great...",
    "What a refreshing change to find a place where...",
    "This is the definition of...",
    "I always leave feeling so...",
    "It’s rare to find this level of...",
    "They’ve earned a loyal customer in me...",
    "I was treated with such...",
    "This place is a true gem for...",
    "The whole experience was filled with...",
    "It’s a breath of fresh air to find...",
    "This is a standout choice for...",
    "I was thrilled by how...",
    "The whole process was so...",
    "It’s amazing to see a place that...",
    "I’ve recommended this place to so many...",
    "The atmosphere alone is worth...",
    "They’ve thought of everything when it comes to...",
    "The care they put into their work is...",
    "I’ve never felt so at ease...",
    "The whole team here is...",
    "I wouldn’t hesitate to recommend...",
    "This place has earned my trust by...",
    "Every aspect of my visit was...",
    "It’s hard to find places that...",
    "I couldn’t believe how easy it was to...",
    "What a fantastic discovery...",
    "This place is a cut above...",
    "They’ve perfected the experience of...",
    "I’ve been telling everyone about...",
    "The passion here is evident in...",
    "You can tell they put their heart into...",
    "It’s not every day you find a place like...",
    "The level of service here is unmatched...",
    "I’ve never felt so well taken care of...",
    "The whole experience was smooth from...",
    "I’m already planning my next visit to...",
    "This place has quickly become my favorite...",
    "I can’t imagine a better place for...",
    "What an incredible experience from...",
    "This place truly exceeded my expectations by...",
    "I was so impressed by how...",
    "It’s always a pleasure coming to...",
    "You can’t go wrong with choosing...",
  ]

  const noOfSentence = 1 + Math.random() * (5 - 1)
  const gptSentences = [
    "use informal abbreviations like gud, ty, etc",
    "very formal",
    "with poor grammer",
    "Vary the word size (40-100) ",
    `Generate response only in ${noOfSentence} sentence`,
  ]

  const handleAllReviews = async () => {
    try {
      setLoading(true)

      // Define the payload
      const payload = {
        merchantInfoId: queMerchData.MerchantsTable._id,
        generateAIReview: generatedText,
        ratings: activeStars,
        question1: queMerchData.QuestionTable.question1,
        q1SelectedKeywords: clickedDishesKeywords, // Correcting array brackets
        question2: queMerchData.QuestionTable.question2,
        q2SelectedKeywords: clickedReviewKeywords, // Correcting array brackets
        noOfCharsOfReview: 50,
      }

      // Make the POST request with the payload
      const response = await axios.post(
        `${API_BASE_URL}/aireviews`,
        payload, // Pass the payload
        {
          headers: {
            "Content-Type": "application/json", // Ensure headers are correct
          },
        },
      )

      if (response && response.data) {
        setAllReviews(response.data) // Set the response data
      } else {
        setError("No reviews found")
      }
    } catch (error) {
      console.error(
        "Error fetching reviews:",
        error.response?.data || error.message,
      )
      setError("An error occurred while fetching reviews.")
      navigate("/pages-404") // Navigate to a 404 page on error
    } finally {
      setLoading(false)
    }
  }
  console.log("queMerchData", queMerchData)

  // Array of rating descriptions

  // Toggle dish keyword selection
  const toggleDishKeyword = keyword => {
    setClickedDishesKeywords(prev =>
      prev.includes(keyword)
        ? prev.filter(k => k !== keyword)
        : [...prev, keyword],
    )
  }

  // Toggle review keyword selection
  const toggleReviewKeyword = keyword => {
    setClickedReviewKeywords(prev =>
      prev.includes(keyword)
        ? prev.filter(k => k !== keyword)
        : [...prev, keyword],
    )
  }

  const handleStarClick = star => {
    setActiveStars(star)
  }
  localStorage.setItem("activeStars", activeStars)

  const handleButtonClick = async () => {
    const selectedKeywords1 = clickedDishesKeywords.join(", ")
    const selectedKeywords2 = clickedReviewKeywords.join(", ")
    setHasSubmitted(true)

    // setButtonLabel("Regenerate AI Review")

    if (activeStars === 0) {
      console.log(starRatingRef.current); // Debugging
      starRatingRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      
      return;
    }
    
    if (clickedDishesKeywords.length === 0)
      {
        keyword1Ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        return;

      } 
    if (clickedReviewKeywords.length === 0) 
    {
      keyword2Ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
    }
    

    const getEmotionForRating = activeStars => {
      if (activeStars <= 2) {
        return lowRatingEmotions[
          Math.floor(Math.random() * lowRatingEmotions.length)
        ]
      } else if (activeStars === 3) {
        return neutralRatingEmotions[
          Math.floor(Math.random() * neutralRatingEmotions.length)
        ]
      } else if (activeStars >= 4) {
        return highRatingEmotions[
          Math.floor(Math.random() * highRatingEmotions.length)
        ]
      }
      return ""
    }

    const emotions = getEmotionForRating(activeStars)

    const getStatingSentenceForRating = activeStars => {
      if (activeStars <= 2) {
        return lowRatingSentence[
          Math.floor(Math.random() * lowRatingSentence.length)
        ]
      } else if (activeStars === 3) {
        return neutralRatingSentence[
          Math.floor(Math.random() * neutralRatingSentence.length)
        ]
      } else if (activeStars >= 4) {
        return highRatingSentence[
          Math.floor(Math.random() * highRatingSentence.length)
        ]
      }
      return ""
    }

    const startSentence = getStatingSentenceForRating(activeStars)

    const randomGptSentence =
      gptSentences[Math.floor(Math.random() * gptSentences.length)]
    const wordsSize = 1 + Math.random() * (100 - 1)

    const message = `consider yourself as a customer and write natural conversational Google review with added human touch.
    avoid mentioning business name, don't use double quotes, don't use formal and common words like extremely, absolutely,
    recently, pleasure, disappointed, terrible, unfortunately, mixed-experienced, uninterested.
    Write a ${activeStars} star review  ${reviewLengthWords} words about conveying an ${emotions} tone.
    the business ${queMerchData.MerchantsTable?.name} for under ${queMerchData.MerchantsTable?.businessCategory} category.
    consider the following questions thoughtfully:
    1) ${queMerchData.QuestionTable.question1} and respective selected keywords for Question1 are ${selectedKeywords1}
    2) ${queMerchData.QuestionTable.question2} and respective selected keywords for Question2 are ${selectedKeywords2}.
    please confirm the review feels like it is written by a human as a real person with the help of variation in structure of sentences
    and shuffle the order of services. ${randomGptSentence}. Responses should be engaging and not repetitive, also it is related to questions
    and respective keywords mentioned in this prompt.Always start a AI generated response based on rating with this sentences ${startSentence}`

    const apikey = process.env.REACT_APP_OPENAI_API_KEY

    try {
      setReviewLoading(true) // Set loading to true
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: message }],
          max_tokens: 200,
          temperature: Math.random() * (1 - 0.1),
        },
        {
          headers: {
            Authorization: `Bearer ${apikey}`,
          },
        },
      )

      const generatedReview =
        response?.data?.choices[0]?.message?.content || "No review generated."
      setGeneratedText(generatedReview)
      localStorage.setItem("generatedReview", generatedReview)

      if (generatedReview !== "No review generated.") {
        console.log("Copy Button Ref:", copyButtonRef.current);
        setButtonLabel("Regenerate AI Review") // Change button label only if the review is successfully generated
        copyButtonRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
         
      }
    } catch (error) {
      console.error("Error generating review:", error)
      setGeneratedText("Error generating review.")
    } finally {
      setReviewLoading(false) // Set loading to false when the request is done
      setIsReviewGenerated(true)
    }
  }

  const copyToClipboard = async () => {
    const isNegativeReviewProtectionEnabled =
      queMerchData?.MerchantsTable?.negativeReviewProtectiontoggle
    const googleReviewURL = queMerchData?.MerchantsTable?.googlereviewURL

    console.log(
      "Negative Review Protection Toggle:",
      isNegativeReviewProtectionEnabled,
    )
    console.log("Active Stars:", activeStars)
    console.log("Google Review URL:", googleReviewURL)

    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        // ✅ Use modern Clipboard API (works on most devices)
        await navigator.clipboard.writeText(generatedText)
        console.log("Text copied using Clipboard API")
      } else {
        // ✅ Fallback for iOS: Use a hidden `textarea`
        const textArea = document.createElement("textarea")
        textArea.value = generatedText
        textArea.setAttribute("readonly", "")
        textArea.style.position = "absolute"
        textArea.style.left = "-9999px"
        document.body.appendChild(textArea)

        textArea.select()
        textArea.setSelectionRange(0, 99999) // Select full text
        const successful = document.execCommand("copy")
        document.body.removeChild(textArea)

        if (!successful) {
          throw new Error("Fallback copy failed")
        }

        console.log("Text copied using textarea fallback")
      }

      // ✅ Show success feedback
      setCopySuccess(true)
      setTimeout(() => setCopySuccess(false), 2000)
      // Navigation logic after copying text
      const handleNavigationAfterCopy = () => {
        if (isNegativeReviewProtectionEnabled) {
          if (activeStars <= 3) {
            console.log("Navigating to negative review form due to low stars")
            navigate("/negative-review-form", { state: { businessname } })
          } else if (activeStars >= 4 && googleReviewURL) {
            console.log("Navigating to Google review link due to high stars")
            window.open(googleReviewURL, "_blank")
          }
        } else if (googleReviewURL) {
          console.log("Opening Google review URL")
          window.open(googleReviewURL, "_blank")
        } else {
          console.log("Google review URL not available")
        }
      }
      // ✅ Perform navigation or redirection after copying
      handleNavigationAfterCopy()
    } catch (err) {
      console.error("Failed to copy text to clipboard:", err)
      alert("Failed to copy text. Please try again.")
    }
  }

  const handleClick = async () => {
    await handleAllReviews() // Wait for API call
    setTimeout(() => {
      copyToClipboard() // Ensure it runs within user interaction
    }, 100)
  }

  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner size="xg" className="me-2" style={{ color: "#2B3A4A" }} />
          Loading...
        </div>
      </>
    )
  }

  if (error) {
    return <p>{error}</p>
  }

  const addCustomKeyword = (
    setCustomKeywords,
    existingKeywords,
    toggleKeyword,
  ) => {
    const newKeyword = prompt("Enter your custom keyword:")
    if (!newKeyword || newKeyword.trim() === "") {
      // alert("Keyword cannot be empty.")
      return
    }
    if (
      existingKeywords.includes(newKeyword.trim()) ||
      customDishKeywords.includes(newKeyword.trim()) ||
      customReviewKeywords.includes(newKeyword.trim())
    ) {
      alert("This keyword already exists.")
      return
    }
    setCustomKeywords(prev => [...prev, newKeyword.trim()])
    toggleKeyword(newKeyword.trim())
  }

  const validateSubmission = () => {
    if (
      clickedDishesKeywords.length === 0 &&
      clickedReviewKeywords.length === 0
    ) {
      setHasSubmitted(true)
    } else {
      setHasSubmitted(false)
      alert("Submission Successful!")
    }
  }
  return (
    <Container className="my-2">
      {/* Header Section */}
      <Row className="text-center">
        <Col>
          {logoUrl ? (
            <img
              src={logoUrl}
              // src={dummyQRCode}
              alt="Logo"
              className="img-fluid"
              style={{ height: "140px" }}
            />
          ) : (
            <p>Logo not available...</p>
          )}
        </Col>
      </Row>
      {/* Star Rating Section */}
      {/* <Row className="text-center mb-1"> */}
      <div className="text-center mb-1" ref={starRatingRef}>
        <Col>
          <div className="d-flex justify-content-center">
            {[1, 2, 3, 4, 5].map(star => (
              <i
                className={`mdi ${activeStars >= star ? "mdi-star" : "mdi-star-outline"}`}
                key={star}
                onClick={() => handleStarClick(star)}
                style={{
                  fontSize: "clamp(40px, 5vw, 80px)",
                  cursor: "pointer",
                  margin: "0 10px",
                  color: activeStars >= star ? "gold" : "gray",
                  transition: "transform 0.3s, color 0.3s",
                  transform:
                    activeStars >= star ? "rotate(50deg)" : "rotate(0deg)",
                }}
              />
            ))}
          </div>

          {hasSubmitted && activeStars === 0 && (
            <div style={{ color: "red" }}>Please select a star rating.</div>
          )}
        </Col>
      </div>

      <Row className="mb-3"  style={{ width: "100%", justifyContent: "center" }}>
        <Col xl="6">
          <div className="mb-4" ref= {keyword1Ref}>
            <h4>{queMerchData.QuestionTable.question1}</h4>
            <div className="d-flex flex-wrap justify-content-start align-items-center" >
              {queMerchData.QuestionTable.question1Keywords
                .concat(customDishKeywords)
                .slice(
                  0,
                  showAllDishesKeywords
                    ? queMerchData.QuestionTable.question1Keywords.length +
                        customDishKeywords.length
                    : 6,
                )
                .map(keyword => (
                  <div key={keyword} style={{ margin: "5px" }}>
                    <div
                      className={`w-auto ${
                        clickedDishesKeywords.includes(keyword)
                          ? "btn-info"
                          : "btn-outline-secondary"
                      }`}
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "10px 15px",
                        backgroundColor: clickedDishesKeywords.includes(keyword)
                          ? `rgba(${parseInt(
                              queMerchData.MerchantsTable.themeColor.slice(
                                1,
                                3,
                              ),
                              16,
                            )}, ${parseInt(
                              queMerchData.MerchantsTable.themeColor.slice(
                                3,
                                5,
                              ),
                              16,
                            )}, ${parseInt(
                              queMerchData.MerchantsTable.themeColor.slice(
                                5,
                                7,
                              ),
                              16,
                            )}, 0.2)`
                          : "transparent",
                        color: "black",
                        fontWeight: clickedDishesKeywords.includes(keyword)
                          ? "bold"
                          : "normal",
                      }}
                      onClick={() => toggleDishKeyword(keyword)}
                    >
                      <span
                        role="img"
                        aria-label="check"
                        className="mr-2"
                        style={{
                          color: clickedDishesKeywords.includes(keyword)
                            ? queMerchData.MerchantsTable.themeColor
                            : "transparent",
                        }}
                      >
                        {clickedDishesKeywords.includes(keyword) ? "✓" : ""}
                      </span>
                      &nbsp;
                      {keyword}
                    </div>
                  </div>
                ))}
              <div style={{ margin: "5px" }}>
                <div
                  className="btn-outline-secondary"
                  style={{
                    border: "1px solid gray",
                    borderRadius: "5px",
                    padding: "10px 15px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    addCustomKeyword(
                      setCustomDishKeywords,
                      queMerchData.QuestionTable.question1Keywords,
                      toggleDishKeyword,
                    )
                  }
                >
                  + Other
                </div>
              </div>
              {!showAllDishesKeywords &&
                queMerchData.QuestionTable.question1Keywords.length +
                  customDishKeywords.length >
                  6 && (
                  <div style={{ margin: "5px" }}>
                    <Button
                      color="link"
                      onClick={() =>
                        setShowAllDishesKeywords(!showAllDishesKeywords)
                      }
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "10px 15px",
                        backgroundColor: "rgba(100, 60, 100, 0.1)",
                        color: "#014f6d",
                      }}
                    >
                      {showAllDishesKeywords
                        ? "Show Less"
                        : `+${
                            queMerchData.QuestionTable.question1Keywords
                              .length +
                            customDishKeywords.length -
                            6
                          }`}
                    </Button>
                  </div>
                )}
            </div>
          </div>
          {hasSubmitted && clickedDishesKeywords.length === 0 && (
            <div style={{ color: "red" }}>
              Please select at least one keyword for question1.
            </div>
          )}
          <div className="mb-4" ref= {keyword2Ref}>
            <h4>{queMerchData.QuestionTable.question2}</h4>
            <div className="d-flex flex-wrap justify-content-start align-items-center" >
              {queMerchData.QuestionTable.question2Keywords
                .concat(customReviewKeywords)
                .slice(
                  0,
                  showAllReviewKeywords
                    ? queMerchData.QuestionTable.question2Keywords.length +
                        customReviewKeywords.length
                    : 6,
                )
                .map(keyword => (
                  <div key={keyword} style={{ margin: "5px" }}>
                    <div
                      className={`w-auto ${
                        clickedReviewKeywords.includes(keyword)
                          ? "btn-info"
                          : "btn-outline-secondary"
                      }`}
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "10px 15px",
                        backgroundColor: clickedReviewKeywords.includes(keyword)
                          ? `rgba(${parseInt(
                              queMerchData.MerchantsTable.themeColor.slice(
                                1,
                                3,
                              ),
                              16,
                            )}, ${parseInt(
                              queMerchData.MerchantsTable.themeColor.slice(
                                3,
                                5,
                              ),
                              16,
                            )}, ${parseInt(
                              queMerchData.MerchantsTable.themeColor.slice(
                                5,
                                7,
                              ),
                              16,
                            )}, 0.2)`
                          : "transparent",
                        color: "black",
                        fontWeight: clickedReviewKeywords.includes(keyword)
                          ? "bold"
                          : "normal",
                      }}
                      onClick={() => toggleReviewKeyword(keyword)}
                    >
                      <span
                        role="img"
                        aria-label="check"
                        className="mr-2"
                        style={{
                          color: clickedReviewKeywords.includes(keyword)
                            ? queMerchData.MerchantsTable.themeColor
                            : "transparent",
                        }}
                      >
                        {clickedReviewKeywords.includes(keyword) ? "✓" : ""}
                      </span>
                      &nbsp;
                      {keyword}
                    </div>
                  </div>
                ))}
              <div style={{ margin: "5px" }}>
                <div
                  className="btn-outline-secondary"
                  style={{
                    border: "1px solid gray",
                    borderRadius: "5px",
                    padding: "10px 15px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    addCustomKeyword(
                      setCustomReviewKeywords,
                      queMerchData.QuestionTable.question2Keywords,
                      toggleReviewKeyword,
                    )
                  }
                >
                  + Other
                </div>
              </div>
              {!showAllReviewKeywords &&
                queMerchData.QuestionTable.question2Keywords.length +
                  customReviewKeywords.length >
                  6 && (
                  <div style={{ margin: "5px" }}>
                    <Button
                      color="link"
                      onClick={() =>
                        setShowAllReviewKeywords(!showAllReviewKeywords)
                      }
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "10px 15px",
                        backgroundColor: "rgba(100, 60, 100, 0.1)",
                        color: "#014f6d",
                      }}
                    >
                      {showAllReviewKeywords
                        ? "Show Less"
                        : `+${
                            queMerchData.QuestionTable.question2Keywords
                              .length +
                            customReviewKeywords.length -
                            6
                          }`}
                    </Button>
                  </div>
                )}
            </div>
          </div>

          {hasSubmitted && clickedReviewKeywords.length === 0 && (
            <div style={{ color: "red" }}>
              Please select at least one keyword for question2.
            </div>
          )}

          <Row className="text-center mb-3">
            <Col>
              <div className="d-flex justify-content-start align-items-center">
                {/* Label for Review Length */}
                {/* <Label
                  for="reviewLength"
                  style={{
                    marginRight: "20px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap", // Prevents text wrapping
                  }}
                >
                  Review Length:
                </Label> */}

                {/* Radio Buttons Container */}
                <div
                  style={{
                    display: "flex",
                    gap: "30px", // Space between radio buttons
                    alignItems: "center",
                  }}
                >
                  {/* Short Radio Button */}
                  <div className="d-flex align-items-center">
                    <Input
                      type="radio"
                      id="shortReview"
                      name="reviewLength"
                      value="short"
                      checked={reviewLength === "short"}
                      onChange={() => setReviewLength("short")}
                      style={{
                        width: "16px",
                        height: "16px",
                        cursor: "pointer",
                      }}
                    />
                    <Label
                      for="shortReview"
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      Short Review
                    </Label>
                  </div>

                  {/* Long Radio Button */}
                  <div className="d-flex align-items-center">
                    <Input
                      type="radio"
                      id="longReview"
                      name="reviewLength"
                      value="long"
                      checked={reviewLength === "long"}
                      onChange={() => setReviewLength("long")}
                      style={{
                        width: "16px",
                        height: "16px",
                        cursor: "pointer",
                      }}
                    />
                    <Label
                      for="longReview"
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      Detailed Review
                    </Label>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Generate Review Button */}

      <Row className="justify-content-center text-center">
        <Col xs="8" md="6" lg="4">
          <Button
            onClick={handleButtonClick}
            className="generate-button"
            style={{
              backgroundColor: queMerchData.MerchantsTable.themeColor,
              color: queMerchData.MerchantsTable.textColor,
              fontSize: "18px",
              padding: "10px 10px",
              width: "100%",
              opacity: buttonLabel === "Regenerate AI Review" ? 0.5 : 1,
              transition: "opacity 0.3s",
            }}
          >
            {buttonLabel}
          </Button>
        </Col>
      </Row>
      

      <Row className="mt-4 d-flex justify-content-center">
        <Col xl="8" lg="10" md="12">
          <Card className="border-0" style={{ minHeight: "150px" }}>
            <CardBody className="d-flex align-items-center justify-content-center">
              {reviewloading ? (
                <Spinner color="primary" />
              ) : (
                <p
                  style={{
                    maxHeight: "auto",
                    overflowY: "auto",
                  }}
                >
                  {generatedText}
                </p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center text-center">
        <Col xs="8" md="6" lg="4">
          {
            <p
              style={{
                color: "black",
                marginBottom: "15px",
                fontSize: "12px",
              }}
            >
              ( AI-generated review has been copied for you. You will now be
              redirected to the review page for <strong>{businessname}</strong>{" "}
              .)
            </p>
          }
          {/* <Button
            onClick={handleClick}
            style={{
              backgroundColor: queMerchData.MerchantsTable.themeColor,
              color: queMerchData.MerchantsTable.textColor,
              fontSize: "18px",
              padding: "10px 10px",
              width: "100%",
            }}
            disabled={!isReviewGenerated}
          >
            Copy & Post
          </Button> */}
          {/* <Button
            ref={copyButtonRef}
            onClick={handleClick}
            style={{
              backgroundColor:
                queMerchData?.MerchantsTable?.themeColor || "#007bff",
              color: queMerchData?.MerchantsTable?.textColor || "#fff",
              fontSize: "18px",
              padding: "10px 10px",
              width: "100%",
              border: "none",
              borderRadius: "5px",
              cursor: isReviewGenerated ? "pointer" : "not-allowed",
              opacity: isReviewGenerated ? 1 : 0.6,
            }}
            disabled={!isReviewGenerated}
          >
            {copySuccess ? "Copied!" : "Copy & Post"}
          </Button> */}
      <button
          ref={copyButtonRef}
          onClick={handleClick}
          style={{
          backgroundColor: queMerchData?.MerchantsTable?.themeColor || "#007bff",
          color: queMerchData?.MerchantsTable?.textColor || "#fff",
          fontSize: "18px",
          padding: "10px 10px",
          width: "100%",
          border: "none",
          borderRadius: "5px",
          cursor: isReviewGenerated ? "pointer" : "not-allowed",
          opacity: isReviewGenerated ? 1 : 0.6,
          }}
         disabled={!isReviewGenerated}
        >
         {copySuccess ? "Copied!" : "Copy & Post"}
      </button>

        </Col>
      </Row>
    </Container>
  )
}

export default AIReviewCreation
